<template lang="pug">
  .header-wrapper
    .toolbar__content
      .left-side
        .nav-icon-wrapper(@click.stop='ddmHandler')
          .nav-icon

        img.logo(@click="logoHandler")

        HeaderLinks.header-links

      .right-side
        Search
        .user-data(v-show="isAuthUser && userProfile.balance_visible_in_top" @click="balanceHandler")
          .balance-wrapper
            .balance__title {{ $t('headerNew.balanceLabel') }}
            .balance-data
              .balance__value {{ moneyFormat(userBalance.value, userBalance.separator, userBalance.minor_unit) }}
              .balance__curr {{ moneyCurency(userBalance.currency) }}

        .msg-wrapper(v-show="isAuthUser" :data-value="2")
          img(:src="require('@/assets/images/top-menu/sunduk-white/bell1.svg')")

        .hlm-wrapper(v-show="isAuthUser" @click="menuHandler('personal')")
          img(:src="require('@/assets/images/top-menu/sunduk-white/user1.svg')")

        .auth-section
          .login(v-show="!isAuthUser" :class="{ 'clicked': isSigninClicked && isSigninVisible, 'disabled': false }" v-if="!isAuthUser" @click="signinHandler") {{ $t('headerNew.loginButton') }}
          .signup(v-show="!isAuthUser" :class="{ 'clicked': isSignupClicked && isSignupVisible, 'disabled': false }" v-if="!isAuthUser" @click="signupHandler") {{ $t('headerNew.signupButton') }}
        .languages-selector(v-if="brand.is_change_lang")
          LanguageSelector(viewSelector="topMenu")
</template>

<script>
import Header from '../Header.vue';

const HeaderLinks = () => import('@/components/topMenu/sundukWhite/HeaderLinksSW');
const LanguageSelector = () => import('@/components/serviceComponents/languageSelector/LanguageSelectorSW');
const Search = () => import('@/components/serviceComponents/search/SearchSW');

export default {
  extends: Header,
  components: {
    HeaderLinks,
    LanguageSelector,
    Search,
  },
};
</script>

<style lang="scss">
  @import "@/assets/styles/top-menu/sunduk-white/header_sw.scss";
</style>
